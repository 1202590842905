#MobileWizard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Heebo', Arial, Helvetica, sans-serif;
  background: white;
}
/*
*   STEPS
*/
#MobileWizard .steps_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
#MobileWizard .steps_container .step {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  transition: .2s ease-in-out;
  padding: 46px 42px 200px 42px;
  box-sizing: border-box;
}
#MobileWizard .steps_container .step[data-step="1"] {
  left: 0;
}
#MobileWizard .steps_container .step[data-step="2"] {
  left: 100%;
}
#MobileWizard .steps_container .step[data-step="3"] {
  left: 200%;
}
#MobileWizard .steps_container .step[data-step="4"] {
  left: 300%;
}
#MobileWizard .steps_container .step[data-step="5"] {
  left: 400%;
}
#MobileWizard .steps_container[data-currstep="1"] .step {
  transform: translateX(0);
}
#MobileWizard .steps_container[data-currstep="2"] .step {
  transform: translateX(-100%);
}
#MobileWizard .steps_container[data-currstep="3"] .step {
  transform: translateX(-200%);
}
#MobileWizard .steps_container[data-currstep="4"] .step {
  transform: translateX(-300%);
}
#MobileWizard .steps_container[data-currstep="5"] .step {
  transform: translateX(-400%);
}
/*
*   FOOTER
*/
#MobileWizard .step_footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
  padding: 24px 0 32px 0;
  box-sizing: border-box;
  z-index: 2;
}
#MobileWizard .step_footer:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 10%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 10%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 10%,rgba(255,255,255,1) 100%);
  pointer-events: none;
  filter: blur(3px);
  z-index: 1;
}
#MobileWizard .step_footer:before {
  content: '';
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  height: calc(100% - 42px);
  background: white;
  pointer-events: none;
  filter: blur(3px);
  z-index: 1;
}
#MobileWizard .step_footer .step_index {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
}
#MobileWizard .step_footer .step_index .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #dfdfdf;
  transition: .2s ease-in-out;
  margin: 0 4px;
  border: 1px solid transparent;
}
#MobileWizard .step_footer .step_index[data-currstep="1"] .dot[data-step="1"],
#MobileWizard .step_footer .step_index[data-currstep="2"] .dot[data-step="2"],
#MobileWizard .step_footer .step_index[data-currstep="3"] .dot[data-step="3"],
#MobileWizard .step_footer .step_index[data-currstep="4"] .dot[data-step="4"],
#MobileWizard .step_footer .step_index[data-currstep="5"] .dot[data-step="5"] {
  background: #0071f1;
  border-color: #368ff5;
  transform: scale(1.2);
}
#MobileWizard .step_footer .btn_next {
  position: absolute;
  bottom: 42px;
  left: 42px;
  width: calc(100% - 84px);
  z-index: 3;
  background: #0071f1;
  text-align: center;
  color: white;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  padding: 18px 0;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #368ff5;
}


/*
*   STEP
*/
#MobileWizard .steps_container .step .step_title p {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: .5px;
  color: #0071f1;
  line-height: 1.1;
  margin: 0;
}
#MobileWizard .steps_container .step .step_title small {
  display: block;
  margin: 10px 0 0 0;
  font-size: 14px;
  letter-spacing: .5px;
  font-weight: 300;
  color: #738091;
  line-height: 1.5;
}
/*
*   STEP 0 - CATEGORIES
*/
#MobileWizard .steps_container .step ul {
  list-style: none;
  margin: 42px 0 0 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}
#MobileWizard .steps_container .step .categories li,
#MobileWizard .steps_container .step .font li {
  float: left;
  width: 100%;
  padding: 14px 12px 14px 46px;
  margin: 4px 0;
  line-height: 1;
  font-size: 13px;
  letter-spacing: .5px;
  color: #0c121e;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url(img/unchecked.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: left 16px center;
  transition: .2s ease-in-out;
}
#MobileWizard .steps_container .step .categories li.selected,
#MobileWizard .steps_container .step .font li.selected {
  background-image: url(img/positive.svg);
  box-shadow: 0px 4px 16px 0px rgb(143 165 176 / 20%);
  background-color: white;
}
#MobileWizard .steps_container .step .themes,
#MobileWizard .steps_container .step .palette {
  margin-left: -12px;
  width: calc(100% + 24px);
}
#MobileWizard .steps_container .step .themes li {
  float: left;
  width: calc(50% - 24px);
  height: 210px;
  margin: 12px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  border: 1px solid rgba(216,222,240,.4);
  position: relative;
}
#MobileWizard .steps_container .step .themes li:after {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url(img/positive.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: .2s ease-in-out;
  transform: scale(0);
}
#MobileWizard .steps_container .step .themes li.selected {
  box-shadow: 0px 4px 16px 0px rgb(143 165 176 / 20%);
}
#MobileWizard .steps_container .step .themes li.selected:after {
  transform: scale(1);
}
#MobileWizard .steps_container .step .palette li {
  float: left;
  width: calc(33.3333% - 24px);
  margin: 10px 12px;
  border-radius: 6px;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  position: relative;
}
#MobileWizard .steps_container .step .palette li > div {
  float: left;
  width: 50%;
  height: 30px;
}
#MobileWizard .steps_container .step .palette li > div:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
#MobileWizard .steps_container .step .palette li > div:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
#MobileWizard .steps_container .step .palette li:after {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url(img/positive.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: .2s ease-in-out;
  transform: scale(0);
}
#MobileWizard .steps_container .step .palette li.selected {
  box-shadow: 0px 4px 16px 0px rgb(143 165 176 / 70%);
}
#MobileWizard .steps_container .step .palette li.selected:after {
  transform: scale(1);
}






#MobileWizard .step[data-step="5"] .step_title {
  text-align: center;
  margin: -36px 0 0 0;
}
#MobileWizard .step[data-step="5"] .step_title img {
  margin: -20px 0 0 -80px;
  width: calc(100% + 160px);
}
#MobileWizard .step[data-step="5"] .step_content {
  display: inline-block;
  width: 100%;
}
#MobileWizard .step .website_preview {
  display: inline-block;
  width: 100%;
  background: #0071f1;
  text-align: center;
  color: white;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  padding: 18px 0;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #368ff5;
  margin: 42px 0 0 0;
  text-decoration: none;
}


#MobileWizard .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: white;
  display: none;
}
#MobileWizard .loader > div {
  display: table;
  width: 100%;
  height: 100%;
}
#MobileWizard .loader > div > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#MobileWizard .loader .shapes-5 {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  color: #0071f1;
  background:
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0);
  background-size: 21px 21px;
  background-repeat:no-repeat;
  animation: sh5 1.5s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes sh5 {
 0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
 33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
 66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
 100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}